<template class="white-content">
  <div id="app">
    <div class="content">
      <notifications></notifications>
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    toggleNavOpen () {
      let root = document.getElementsByTagName('html')[0]
      root.classList.toggle('nav-open')
    }
  },
  mounted () {
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen)
  }
}
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
<style src="./assets/styles.css" lang="css"></style>
